#payment-amount::-webkit-input-placeholder {
    color: black; 
    opacity: 0.3;
}

.payment-card {
    cursor: pointer !important;
}

.payment-card:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.override-sq-payment {
    padding:2.5rem !important;
    line-height: 1rem !important;
}

.payment-card-td {
  border-top: none !important;
  padding: .125rem 0  !important;
  text-align: left !important;
}

.payment-card-td > span {
  font-size:.925rem !important;
}

.payment-card-td > p {
  font-size:.925rem !important;
}
