#tab-container {
    position: relative;
    margin: auto; 
    padding: 2rem 0;
}

#bond-table {
    margin-bottom:0 !important;
}

#bond-container > nav, #bond-container > .tab-content  {
    min-width:60rem !important;
}

#bond-container > .tab-content {
    border-left:1px solid #dee2e6;
    border-right:1px solid #dee2e6;
    border-bottom:1px solid #dee2e6;
    border-bottom-left-radius:.25rem;
    border-bottom-right-radius:.25rem;
}

.bond-table-cell {
    width: 25%;
    border-top: none;
    padding-top: 0;
}

.add-image, .add-image-inverted {
    width:.55rem;
    margin-right:.25rem;
    margin-top:-.1rem;
}

.add-image-inverted {
    filter: brightness(0) invert(1);
}

.row-red-cross {
    position:absolute;
    top:.45rem;
    right:-.65rem;
    width:1.1rem;
    cursor:pointer;
}

.remove-row-cross:hover {
    opacity:.67;
}

.bond-table-td {
    border-top:none !important;
    padding-top:0 !important; 
}

.build-table > a {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}