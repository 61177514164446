.tax-form-button {
    float:left;
    margin-top:1rem;
}

.tax-form-submit {
    float:right;
    margin-top:1rem;
}

#bond-tax-form > select {
    margin-bottom:1rem !important;
}

.bond-tax-table {
    margin-top:1.5rem !important;
    border-left:1px solid #dee2e6;
    border-right:1px solid #dee2e6;
    border-bottom:1px solid #dee2e6;
}

.bond-tax-table thead, th{
    font-size:.75rem;
    text-transform: uppercase !important;
    border-bottom:none !important;
}

.bond-tax-table th, td {
    text-align:center;
}

.card-header, .card-header-tablet {
    font-size: .75rem !important;
    font-weight:bold !important;
}

.card-header-mobile, .card-header-mobile-sm {
    padding:.5rem !important;
    font-size: .5rem !important;
    font-weight:bold !important;
}

.card-tax {
    border-bottom-left-radius:0 !important ;
    border-bottom-right-radius:0 !important;
}

.card-tax-header {
    padding:.25rem !important;
    text-align:center;

}

.card-tax-title {
    font-size: 1rem !important;
    margin-bottom:0 !important;
    font-size:1.25rem !important;
}

.card-tax-body {
    text-align:center;
    margin-top:.25rem;
    margin-bottom:.25rem;
    padding:1.25rem !important;
}
