#login-page > .tab-content {
    width:23rem !important;
    margin:auto !important;
    border-top:1px solid #dee2e6 !important;
    border-radius: .25rem !important;
    /* border-top-left-radius: .25rem !important; */
}

#login-page > .nav-tabs a {
    width:50% !important;
    text-align:center;
}

#login-form, #register-form {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

#login-form {
    border-top-left-radius: .25rem;
}

#register-form {
    border-top-right-radius: .25rem;
}


#login-form input, #register-form input, #forgot-password-form input, #password-reset-form input {
    font-size:.9rem !important;
    background-color: rgb(232, 240, 254);
    padding-right:2rem;
}

.register-checkmark, .register-cross {
    position:absolute;
    top:2.45rem;
    right:.5rem;
    width:1.25rem;
}

.forgot-checkmark, .forgot-cross {
    position:absolute;
    top:1rem;
    right:.5rem;
    width:1.25rem;
}

.alert-show {
    width: 22.5rem; 
    margin: auto; 
    text-align: center;
    font-size: .9rem;
    opacity:1;
    transition-duration: 300ms !important;
}

.alert-hidden {
    width: 22.5rem; 
    margin: auto;
    text-align: center;
    font-size: .9rem;
    opacity:0;
    transition-duration: 300ms !important;
}

.google-login {
    width:23rem !important;
    box-shadow:none !important;
    border:1px solid #dee2e6 !important;
    border-radius:.25rem !important;
}

.google-login:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
}


.google-login > div {
    margin-left:5.5rem !important;
}

.google-login > span {
    margin-top:.1rem !important;
}
