.home-jumbotron, .home-jumbotron-tablet, .home-jumbotron-mobile, .home-jumbotron-mobile-sm {
    font-weight: 500;
    background-image:url('../images/bonds-background-image.jpg');
    background-repeat: no-repeat;
    border-bottom:1px solid #d3d3d3;
    z-index:-1;
}

.home-jumbotron-mobile, .home-jumbotron-tablet {
    padding:6rem 3.5rem 4rem 3.5rem !important;
}

.home-jumbotron {
    background-size:cover;
    padding:6rem 5rem !important;
    height:30rem;
    background-size:125% !important;
}

.home-jumbotron-mobile {
    background-size:200% !important;
    height:25rem;
}

.home-jumbotron-tablet {
    background-size:150% !important;
    height:25rem;
}

.home-jumbotron-mobile-sm {
    padding:6rem 2rem 4rem 2rem !important;
    background-size:425% !important;
    height:27.5rem;
}

.home-jumbotron h1 {
    margin-top:1rem;
    font-size:2rem;
    font-family: 'Roboto', sans-serif;
}

.home-jumbotron-tablet h1 {
    font-size:1.75rem;
    font-family: 'Roboto', sans-serif;
}

.home-jumbotron-mobile h1, .home-jumbotron-mobile-sm h1 {
    font-size:1.25rem !important;
    font-family: 'Roboto', sans-serif;
}

.home-jumbotron-mobile h2, .home-jumbotron-mobile-sm h2 {
    font-size:.875rem !important;
    font-family: 'Roboto', sans-serif;
}

.home-container {
    width:55% !important;
    margin:auto !important;
    margin-bottom:2rem !important;
}

.home-container-tablet {
    width:75% !important;
    margin:auto !important;
    margin-bottom:2rem !important;
}


.home-container-mobile, .home-container-mobile-sm {
    width:100% !important;
    padding: 0 1rem !important;
    margin-bottom:2rem !important;
}

.home-choose-container, .home-choose-container-mobile,  .home-choose-container-tablet {
    width:27.5rem;
    white-space: nowrap;
    background:#fff;
    padding:1rem;
    border:1px solid black;
    text-transform: uppercase;
    font-size:1.1rem;
} 

.home-choose-container-mobile-sm {
    width:16.75rem;
}

.home-choose, .home-choose-mobile, .home-choose-tablet {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.home-choose-mobile-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.home-card {
    flex-direction: column;
    margin:auto;
    margin-top:2rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.home-card-mobile, .home-card-mobile-sm, .home-card-tablet {
    flex-direction: column;
    margin-top:2rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.home-card-header {
    background:rgba(0,0,0,.02);
    font-size:1.25rem !important;
    font-weight:normal;
}

.home-card-body {
    padding:1.25rem !important;
    text-align:justify !important;
}

.home-list-item {
    padding: .25rem 0 !important;
    border:none !important;
    text-align:left !important;
  }

.modal-body > .input-group-text {
    background:#fff !important;
}