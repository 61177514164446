body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nav-background-home{
    position:absolute !important;
}

.nav-background {
    width:100%;
    font-size:1rem !important;
    background-image:url('../images/bonds-background-banner.jpg');
    background-size:102.5%;
    background-repeat: no-repeat;
    border-bottom:1px solid #d3d3d3;
}

.brand, .brand-tablet, .brand-mobile, .brand-mobile-sm {
    margin-right:.25rem;
}

.brand-mobile, .brand-mobile-sm {
    margin-top:-.25rem;
}

.brand-img {
    height:2.5rem;
    width:2.5rem;
}

.brand-img-tablet, .brand-img-mobile {
    height:2rem;
    width:2rem;
}

.brand-img {
    margin:0 1rem 0 4rem;
}

.brand-img-mobile-sm {
    height:1.25rem;
    width:1.25rem;
}

.nav-link:hover, .nav-link:focus {
    color:#707070 !important;
}

.bond-nav-link, .nav-link {
    color:#2a3132 !important;
    text-transform: capitalize !important;
    font-size:.95rem !important;
    font-family: 'Roboto', sans-serif !important;
    text-decoration: none !important;  
    /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;  */
    font-weight:normal !important;
}

.bond-nav-link {
    z-index:1;
}

.bond-nav-link a, .nav-link a {
    color:#2a3132 !important;
}

.bond-nav-link-mobile-sm {
    padding:.5rem !important;
    font-size:.65rem !important;
}

.bond-nav-link-mobile-sm > .dropdown-toggle {
    padding:0 !important;
    font-size:.65rem !important;
}

.login-logout-link:hover {
    opacity:.75 !important;
}

.user-text {
    position: absolute; 
    top: 1.75rem;
    right: 0; 
    margin: 0;
    font-size: .75rem;
    opacity:1;
}

.user-text-fade {
    position: absolute; 
    top: 1.75rem;
    right: 0; 
    margin: 0;
    font-size: .75rem;
    opacity:0;
    transition:opacity 2s;
}