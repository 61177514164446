body {
    margin-bottom:1rem;
}

.flip-arrows {
    width:5rem !important;
    animation:rotation 2s infinite linear;
    -webkit-animation: rotation 2s infinite linear;
  }
  
  @keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
  }
  
.bond-container {
    position:relative;
    width:65%;
    margin:auto;
    padding-top:1rem;
}

.wizard-description, .wizard-description-hidden {
    float:right;
    position:relative;
    top:.75rem;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius:.2rem;
    cursor:pointer;
    padding:.4rem !important;
}

.wizard-description {
    padding: .75rem;
    border-bottom:1px solid #fff !important;
    border-bottom-left-radius:0 !important;
    border-bottom-right-radius:0 !important;
}

.wizard-description-hidden {
    padding:.75rem;
}

.wizard-plus-sign {
    float: left; 
    width: .75rem;
    height: .75rem;
    margin-top:.4rem;
}

.wizard-minus-sign {
    float: left; 
    width: .75rem;
    height: .25rem;
    margin-top:.65rem;
}

.wizard-description-p {
    padding: .75rem 1rem;
    margin-top:.5rem;
    text-align:justify;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius:.2rem;
    border-top-left-radius:0 !important;
}

.bond-container-tablet, .bond-container-mobile, .bond-container-mobile-sm {
    width:100%;
    padding:0 1rem;
}

.bond-container-tablet {
    margin-top:2rem;
}

.bond-container-mobile, .bond-container-mobile-sm {
    margin-top:.5rem;
}

.form-label, .form-label-tablet {
    font-size:.95rem;
}

.form-label-mobile, .form-label-mobile-sm {
    font-size:.625rem;
}

input[class^="form-month"] {
    flex:1 !important;
    padding:.25rem !important;
    text-align:center !important;
}

.form-month-mobile, .form-month-mobile-sm {
    height:2rem !important;
    line-height:.5rem !important;
    font-size:.75rem !important;
}

.form-seperator-mobile, .form-seperator-mobile-sm {
    padding-left:.35rem !important;
    padding-right:.35rem !important;
    height:2rem !important;
    line-height:2rem !important;
    font-size:.9rem !important;
}

input[class^="form-year"] {
    flex:1.5 !important;
    padding:.25rem !important;
    text-align:center !important;
    margin-left:-1px !important;
}

.form-year-mobile, .form-year-mobile-sm {
    height:2rem !important;
    line-height:1rem !important;
    font-size:.75rem !important;
}

.bond-input-mobile, .bond-input-mobile-sm {
    height:2rem !important;
    line-height:1rem !important;
    font-size:.75rem !important;
}

.form-button, .form-button-tablet {
    position:absolute;
    top:0;
    right:.3rem;
    padding:.25rem !important;
    font-size:.5rem !important;
    font-weight:bold !important;
}

.form-button-mobile, .form-button-mobile-sm {
    position:absolute;
    top:0;
    right:.25rem;
    padding:.15rem !important;
    font-size:.55rem !important;
}

.card-header, .card-header-tablet {
    font-size: .75rem !important;
    font-weight:bold !important;
}

.card-header-mobile, .card-header-mobile-sm {
    padding:.5rem !important;
    font-size: .5rem !important;
    font-weight:bold !important;
}

.card-title, .card-title-tablet {
    font-size: 1.25rem !important;
}

.card-title-mobile, .card-title-mobile-sm {
    font-size: 1rem !important;
}

.card-body, .card-body-tablet, .card-body-mobile, .card-body-mobile-sm {
    text-align:center;
  }

.card-body-mobile, .card-body-mobile-sm {
padding: .5rem !important;
}

table[class^="bond-table"]{
    margin:0;
    border:1px solid #dee2e6;
}

.bond-table th {
    border-top:none !important;
}

.bond-table td, .bond-table th {
    border-bottom-width:1px !important;
}

.bond-table-head, .bond-table-head-tablet {
    text-align: center !important;
    font-size: .75rem !important;
    text-transform: uppercase;
    white-space:nowrap !important;
}

.bond-table-head-mobile, .bond-table-head-mobile-sm {
    text-align: center !important;
    font-size: .5rem !important;
    text-transform: uppercase;
    white-space:nowrap !important;
}

.bond-table-row, .bond-table-row-tablet {
    text-align: center !important;
    font-size: 1rem !important;
}

.bond-table-row-mobile, .bond-table-row-mobile-sm {
    text-align: center !important;
    font-size: .75rem !important;
}

.close {
    position: absolute;
    top: .75rem;
    right: -1.25rem;
}

.close-mobile{
    position: absolute;
    top:.1rem;
    right:-1.25rem;
}

.ss-col-one {
    margin-top:1rem;
    flex:1 !important;
}

.ss-col-two {
    margin-top:1rem;
    flex:3.1 !important;
}

#custom-file {
    cursor:pointer !important;
}

.ss-attachment-mobile > label, .ss-attachment-mobile-sm > label {
    font-size:.75rem !important;
  }

  .custom-file-label::after {
      float:left !important;
  }

  .export-dropdown > button, .export-dropdown-tablet > button {
    padding: .25rem !important;
    font-size: .75rem !important;
  }

  .export-dropdown-mobile > button, .export-dropdown-mobile-sm > button {
    padding:.15rem !important;
    font-size:.55rem !important;
  }

  .export-csv-link {
    color: #000 !important;
    text-decoration: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .dropdown-toggle::after {
      vertical-align:middle !important;
  }

  .bond-alerts {
    width: 100% !important;
    margin-top:1.25rem !important;
    text-align:center !important;
  }

  .highlight {
    background:#fff3cd !important;
  }

  .page-link {
    padding:.1rem .25rem !important;
    /* font-size:.75rem !important; */
    border-radius:0 !important;
}

  .pagination-show-next-ten > a {
    margin-top:.05rem !important;
    border-top:none !important;
    border-right:none !important;
    border-bottom:none !important;
  }

  .pagination-show-next-ten > a:hover {
      background:#fff !important;
  } 

  .pagination-show-prev-ten > a {
    margin-top:.05rem !important;
    border-top:none !important;
    border-left:none !important;
    border-bottom:none !important;
  }

  .pagination-show-prev-ten > a:hover {
      background:#fff !important;
  } 

.abs-background{
    position:relative;
    width:100%;
    padding-bottom:.5rem;
}

.alert-wizard {
    position:absolute !important;
    top:1rem;
    left:0;
    padding-top:.5rem !important;
    padding-bottom:.5rem !important;
}

.view-errors {
    position: relative;
    list-style: none;
    margin-bottom:0;
    cursor:pointer;
    color:#ff4000;
    margin-left:.5rem;
}

#error-arrow:after {
    content:"▷";
    display: inline-block;
    transition-duration: 250ms;
    font-size:.9rem;
}

#error-arrow-rotate:after {
    content:"▷";
    display: inline-block;
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
    transition-duration: 250ms;
    font-size:.9rem;
  }